<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import appConfig from "../../../app.config";

import axios from 'axios';

export default {
  page: {
    title: "Sign Out",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  created: function () {
    localStorage.clear();

    var vm = this;
    axios.post('/api/auth/signout', {}, { withCredentials: true })
    .then(function () {
      vm.$router.go(-1);
    })
    .catch(function () {
      vm.$router.go(-1);
    });
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  }
};
</script>
    
<template>
  <Layout>
    <PageHeader :title="title" :description="description" />
  </Layout>
</template>